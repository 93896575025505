// porto template

$brand-primary: #002776; // blue 
$brand-secondary: #ffdf00; //yellow
$brand-tertiary: #009b3a; // green

$primary: lighten($brand-primary, 10%);
$secondary: lighten($brand-secondary, 20%);

$headings-color: $brand-primary;

$link-color: $brand-tertiary;
$link-color-dark: lighten($brand-tertiary, 5%);

$navbar-light-color: lighten($brand-secondary, 43%);
$navbar-light-hover-color:          $brand-secondary;
$navbar-light-active-color:         $brand-secondary;
$navbar-light-disabled-color:       desaturate($primary, 100%);
$navbar-light-toggler-border:       $brand-secondary;

@import "base";
// @import "clock";

#topnav {
	background-color: $brand-tertiary;
}

#jumbotron {
	@include gradient-radial($body-bg, $secondary);

	&[data-background] {
		color: $white;
		background: $gray-800 none;

		p, h1, h2, h3, h4 {
			text-shadow: 1px 1px 4px $black;
		}

		a:not([class]) {
			color: $brand-secondary;
		}

		h1, h2, h3, h4 {
			color: $white;
		}

		.input-group {
			border-radius: $border-radius-lg;
			box-shadow: 1px 1px 4px $black;
		}
	}
}

footer {
	background-color: $secondary;
	color: darken($brand-tertiary, 15%);
}

@include color-mode(dark) {
	#jumbotron {
		@include gradient-radial($body-bg-dark, $body-secondary-bg-dark);
	}

	footer {
		background-color: $body-secondary-bg-dark;
		color: $gray-500;

		a, .nav-link {
			color: $gray-200;

			&:hover,
			&:focus {
				color: $white;
			}

		}
	}
}
